'use client';

import { useSetArticleId } from '@haaretz/s-atoms/articleId';
import { useSetAuthors } from '@haaretz/s-atoms/authors';
import { useMutatorsClientTeasersOnPage } from '@haaretz/s-atoms/clientTeasersOnPage';
import { useSetCookie } from '@haaretz/s-atoms/cookie';
import { useSetPage } from '@haaretz/s-atoms/page';
import { useSetPageStatusToken } from '@haaretz/s-atoms/pageStatusToken';
import { useSetPageType } from '@haaretz/s-atoms/pageType';
import { useSetPaywallType } from '@haaretz/s-atoms/paywallType';
import { useSetPreviewId } from '@haaretz/s-atoms/previewId';
import { useSetRenderingKind } from '@haaretz/s-atoms/renderingKind';
import { useSetTags } from '@haaretz/s-atoms/tags';
import usePathChanged from '@haaretz/s-common-hooks/usePathChanged';
import useBi from '@haaretz/s-use-bi';
import React from 'react';

import type { BiDataOverrides } from '@haaretz/s-data-structure-types';
import type { CommonPageLayoutFragment } from '@haaretz/s-fragments/CommonPageLayout';
import type { PageType, RenderingKind } from '@haaretz/s-types';

const requestBiData: BiDataOverrides = {};

interface CommonUtilsProps {
  pageType: PageType;
  previewId: string | null;
  renderingKind: RenderingKind;
  pageContentId: CommonPageLayoutFragment['contentId'];
}

export default function CommonUtils({
  pageContentId,
  pageType,
  previewId,
  renderingKind,
}: Readonly<CommonUtilsProps>) {
  const biRequest = useBi('pageview');

  const setPageType = useSetPageType();
  const setPagePaywallLevel = useSetPaywallType();
  const setRenderingKind = useSetRenderingKind();
  const setAuthors = useSetAuthors();
  const setTags = useSetTags();
  const setPage = useSetPage();
  const setCookie = useSetCookie();
  const setPreviewId = useSetPreviewId();
  const setArticleId = useSetArticleId();
  const setPageStatusToken = useSetPageStatusToken();
  const { clearClientTeasers } = useMutatorsClientTeasersOnPage();

  React.useEffect(() => {
    setPageType(pageType);
  }, [pageType, setPageType]);

  React.useEffect(() => {
    setPagePaywallLevel('free');
  }, [setPagePaywallLevel]);

  React.useEffect(() => {
    setRenderingKind(renderingKind);
  }, [renderingKind, setRenderingKind]);

  React.useEffect(() => {
    setAuthors(null);
  }, [setAuthors]);

  React.useEffect(() => {
    setTags(null);
  }, [setTags]);

  React.useEffect(() => {
    setPageStatusToken(null);
  }, [setPageStatusToken]);

  React.useEffect(() => {
    clearClientTeasers();
  }, [clearClientTeasers]);

  React.useEffect(() => {
    setPage({
      pageContentId,
      articleSectionId: undefined,
    });
  }, [pageContentId, setPage]);

  React.useEffect(() => {
    setPreviewId(previewId);
  }, [previewId, setPreviewId]);

  React.useEffect(() => {
    setArticleId(null);
  }, [setArticleId]);

  React.useEffect(() => {
    if (!!pageContentId && !!biRequest) {
      const timeout = setTimeout(() => biRequest(requestBiData), 500);

      return () => timeout && clearTimeout(timeout);
    }

    return undefined;
  }, [biRequest, pageContentId]);

  /* istanbul ignore next */
  const onPathChangedCb = React.useCallback(() => {
    setCookie();
  }, [setCookie]);

  usePathChanged(onPathChangedCb);

  return null;
}
