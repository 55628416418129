import(/* webpackMode: "eager" */ "/workspace/node_modules/.pnpm/next@14.2.6_patch_hash=rcpresklxxhhtjzse7m34ogpn4_@babel+core@7.22.10_babel-plugin-macros@3.1_aopt7sfpfpgzl5dyth5d7hbcbu/node_modules/next/dist/client/script.js");
;
import(/* webpackMode: "eager" */ "/workspace/node_modules/.pnpm/next@14.2.6_patch_hash=rcpresklxxhhtjzse7m34ogpn4_@babel+core@7.22.10_babel-plugin-macros@3.1_aopt7sfpfpgzl5dyth5d7hbcbu/node_modules/next/dist/shared/lib/lazy-dynamic/dynamic-bailout-to-csr.js");
;
import(/* webpackMode: "eager" */ "/workspace/node_modules/.pnpm/next@14.2.6_patch_hash=rcpresklxxhhtjzse7m34ogpn4_@babel+core@7.22.10_babel-plugin-macros@3.1_aopt7sfpfpgzl5dyth5d7hbcbu/node_modules/next/dist/shared/lib/lazy-dynamic/preload-css.js");
;
import(/* webpackMode: "eager" */ "/workspace/pkgs/ds-atoms/s-script3rd-party/dist/Script3rdParty.js");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/workspace/pkgs/ds-organisms/s-scripts/dist/components/AccessListByIp.js");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/workspace/pkgs/ds-organisms/s-scripts/dist/components/ChartBeat/ChartBeatClient.js");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/workspace/pkgs/ds-organisms/s-scripts/dist/components/CookieConsent/CookieConsentClient.js");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/workspace/pkgs/ds-organisms/s-scripts/dist/components/FacebookPixel/FacebookPixelOnNavigation.js");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/workspace/pkgs/ds-organisms/s-scripts/dist/components/GaEvents/GAConfig.js");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/workspace/pkgs/ds-organisms/s-scripts/dist/components/GeoEdge/GeoEdgeClient.js");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/workspace/pkgs/ds-organisms/s-scripts/dist/components/Outbrain/OutbrainObctClient.js");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/workspace/pkgs/ds-organisms/s-scripts/dist/components/Outbrain/OutbrainPixel/OutbrainPixelOnNavigation.js");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/workspace/pkgs/ds-organisms/s-scripts/dist/components/Outbrain/OutbrainPixelContent.js");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/workspace/pkgs/ds-organisms/s-scripts/dist/components/Permutive/PermutiveArticlePage/PermutiveArticlePageOnNavigation.js");
;
import(/* webpackMode: "eager" */ "/workspace/pkgs/ds-organisms/s-scripts/dist/components/PushWooshClient.js");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/workspace/pkgs/ds-organisms/s-scripts/dist/components/Taboola/TaboolaOnNavigation.js");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/workspace/pkgs/ds-organisms/s-scripts/dist/components/Taboola/TaboolaPixelContent.js");
;
import(/* webpackMode: "eager" */ "/workspace/pkgs/utils/s-gtag-utils/dist/GoogleAnalytics/CustomNextGoogleAnalytics.js");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/workspace/pkgs/utils/s-section-utils/dist/AutoRefresh/AutoRefresh.js");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/workspace/pkgs/utils/s-section-utils/dist/CommonUtils/CommonUtils.js");
